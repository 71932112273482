import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const AuthContext = createContext();
// const authLink = setContext(async () => {
//   let token = sessionStorage.getItem('token')
//   const { exp } = jwtDecode(token)
//   // Refresh the token a minute early to avoid latency issues
//   const expirationTime = (exp * 1000) - 60000
//   if (Date.now() >= expirationTime) {
//     token = await refreshToken()
    
//   }
//   return {
//     // you can set your headers directly here based on the new token/old token
//     headers: {
//       ...
//     }
//   }
// })
const parseJwt = (token) =>{
  try{
    return JSON.parse(atob(token.split('.')[1]));
  } catch(e){
    return null;
  }
}

const AuthProvider = ({children}) =>{
    const location = useLocation();
    const [token,_setToken] = useState(sessionStorage.getItem("token"))
    const setToken = (token) =>{
      _setToken(token)
    }
    useEffect(() => {
        if (token) {
          const decodedJwt = parseJwt(token)
          if (decodedJwt.exp * 1000 < Date.now()){
              setToken(null)
              sessionStorage.removeItem("token")
            return
          }
          setToken(token)
          sessionStorage.setItem("token",token)
        } else {
          setToken(null)
          sessionStorage.removeItem("token")
        }
      }, [location,token]);
    const contextValue = useMemo(
        () => ({
          token,
          setToken,
        }),
        [token]
      );
    return (
        <AuthContext.Provider value={contextValue}>
          {children}
        </AuthContext.Provider>
      );
}
export const useAuth = () =>{
    return useContext(AuthContext)
}
export default AuthProvider;