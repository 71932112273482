import SubCategoryManager from '../components/SubCategoryManager';
import { useAuth } from '../provider/authProvider';
import Preloader from '../helper/Preloader';
import { Navigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
const SubCategoryManagerPage = () =>{
    let {id,id_sub} = useParams();
    if (id_sub === undefined | id_sub === null | id_sub === 0){
        id_sub = null
    }
    if (id === undefined | id === null | id === 0){
        id = null
    }
    const {token,loading} = useAuth()
    if (loading){
        return <Preloader />
    }
    if (!token){
        return <Navigate to='/login' replace />
    }
    console.log(id)
    console.log(id_sub)
    return (<SubCategoryManager idCategory={id} idSubCategory={id_sub}/>)
}

export default SubCategoryManagerPage;