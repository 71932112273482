import React, { useEffect, useState } from 'react';
import { Label,Row,Col } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import { useNavigate } from 'react-router-dom';
import FileInput from './FileInput';


const UserForm = ({ user }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: user });
  
  const textFields = [
    { value: user ? user.email : "", label: 'email' ,validation:{required:true}},
    { value:"", label: 'reimposta_password' ,validation:{required:false}},
  ];

  
  const onSubmit = (data) => {
    if (JSON.stringify(data) === JSON.stringify(user)) {
      alert('Dati non modificati, aggiornamento evitato');
      return;
    }
    
    const formData = new FormData();
    console.log(data)
    formData.append("data",JSON.stringify(data));
    const url = `/api/admin/users/1`;
    const method = "PATCH";
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
        if (response.ok){
            let str_resp = "modifica avvenuta correttamente"
            alert(str_resp)
            navigate("/admin")
        }else if(response.status === 401){
          alert("sessione interrotta, rieffettuare il login")
          navigate("/login")
        } 
        else{
          alert("errore : " + response.json())
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      <button type="submit" className="btn btn-primary m-3">Save</button>
    </form></>
  );
};

const UserManager = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = useAuth();
  
  useEffect(() => {
    async function fetchData() {
      let userResponse = null;
      let userData = null;
      userResponse = await fetch(`/api/admin/users/`,{method:"GET",headers: {
        "Authorization": "Bearer " + auth.token,
      },});
      if ((userResponse!== null && !userResponse.ok)) {
        console.log('error');
        return;
      }
      if (userResponse !== null){
        userData = await userResponse.json();
      }
    return [userData];
    }

    fetchData().then((data) =>{
        setUser(data[0])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50 row" >
        <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
        </div>
        <div className='col-10'>
          <h2>Modifica utente</h2>
          </div>
        </div>
      </div>
    <div className="row align-items-center justify-content-center m-3">    
      <div className="col-12 section-bg ">
        <UserForm user={user}  />
        </div>
        </div>
       </section> 
  );
};

export default UserManager;
