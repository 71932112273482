import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../assets/images/home/home_1.jpg";
import img2 from "../assets/images/home/home_2.jpg";
import img3 from "../assets/images/home/home_3.jpg";
import img4 from "../assets/images/home/home_4.jpg";
import img5 from "../assets/images/home/home_5.jpg";

import bannergradient from "../assets/images/gradients/banner-gradient.png";
import moon1 from "../assets/images/shapes/element-moon1.png";
import moon2 from "../assets/images/shapes/element-moon2.png";
import { useState } from "react";
import video from '../assets/images/home/video_campo_calcio.mp4';
const BannerOne = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-right fs-2" />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={className} onClick={onClick}>
        <i className="las la-arrow-left fs-2"></i>
      </button>
    );
  }
  const navigate = useNavigate();
  const [inputValue,setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/prodotti', { state: { searchQuery: inputValue } });
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
    ],
  };
  const images = [
    img1,img2,img3,img4,img5
  ];

  return (
    <>
      <section className="hero section-bg z-index-1">
        <img
          src={bannergradient}
          alt=""
          className="bg--gradient white-version"
        />
        <img
          src={moon1}
          alt=""
          className="element one"
        />
        <img
          src={moon2}
          alt=""
          className="element two"
        />
        <div className="container mw-100 fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5  order-lg-1 order-2 ">
              <div className="hero-inner position-relative pe-lg-5">
                <div>
                  <h1 className="hero-inner__title mt-3">
                    Impianti Illuminazione LED
                  </h1>
                  <p className="hero-inner__desc fs-2">
                    Specializzati in progetti su misura e consulenza
                  </p>
                  <form onSubmit={handleSubmit}>
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm"
                      placeholder="Cerca tra i nostri prodotti"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    <button
                      type="submit"
                      className="btn btn-main btn-icon icon border-0"
                    >   
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                      </svg>
                    </button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              {/* <div className="hero-thumb"> */}
              <div className="popular-slider arrow-style row">
              {/* <video src={video} autoPlay={true} loop={true} controls="controls"/> */}
                <Slider {...settings}>
                  {images.map((img, index) => (
                    <div key={index}>
                      <img src={img} alt={`slide ${index + 1}`} className="img-fluid rounded"/>
                    </div>
                  ))}
                </Slider>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        
      </section>
    </>
  );
};

export default BannerOne;
