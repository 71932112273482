import FooterOne from "../components/FooterOne"
import HeaderOne from "../components/HeaderOne"
import Preloader from "../helper/Preloader"
import React from "react"

const InformativaPrivacy = () =>{
    return (
        <React.Fragment>
        <Preloader />
        <HeaderOne />
        <div class="informativa-container">
        <h1>INFORMATIVA</h1>
    <p>L'interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l'obbligo di cancellare senza ingiustificato ritardo i dati personali, se sussiste uno dei motivi seguenti:</p>
    <ul>
        <li>a) i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;</li>
        <li>b) l'interessato revoca il consenso su cui si basa il trattamento conformemente all'articolo 6, paragrafo 1, lettera a), o all'articolo 9, paragrafo 2, lettera a), e se non sussiste altro fondamento giuridico per il trattamento;</li>
        <li>c) l'interessato si oppone al trattamento ai sensi dell'articolo 21, paragrafo 1, e non sussiste alcun motivo legittimo prevalente per procedere al trattamento, oppure si oppone al trattamento ai sensi dell'articolo 21, paragrafo 2;</li>
        <li>d) i dati personali sono stati trattati illecitamente;</li>
        <li>e) i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento;</li>
        <li>f) i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'articolo 8, paragrafo 1 del Reg. UE 2016/679</li>
    </ul>
    <h2>10.3 Diritto di cui all’art. 18 Diritto di limitazione di trattamento</h2>
    <p>L'interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:</p>
    <ul>
        <li>a) l'interessato contesta l'esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali dati personali;</li>
        <li>b) il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l'utilizzo;</li>
        <li>c) benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all'interessato per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</li>
        <li>d) l'interessato si è opposto al trattamento ai sensi dell'articolo 21, paragrafo 1, Reg UE 2016/679 in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell'interessato.</li>
    </ul>
    <h2>10.4 Diritto di cui all’art.20 Diritto alla portabilità dei dati</h2>
    <p>L'interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento</p>
    <h2>11. Revoca del consenso al trattamento</h2>
    <p>Le è riconosciuta la facoltà di revocare il consenso al trattamento dei Suoi dati personali, inviando una raccomandata A/R al seguente indirizzo: Via Romagnoli 12 04100 Latina corredato da fotocopia del suo documento di identità, con il seguente testo: &lt;&gt;. Al termine di questa operazione i Suoi dati personali saranno rimossi dagli archivi nel più breve tempo possibile.</p>
    <p>Se desidera avere maggiori informazioni sul trattamento dei Suoi dati personali, ovvero esercitare i diritti di cui al precedente punto 7, può inviare una raccomandata A/R al seguente indirizzo: Via Romagnoli 12 04100 Latina Prima di poterle fornire, o modificare qualsiasi informazione, potrebbe essere necessario verificare la Sua identità e rispondere ad alcune domande. Una risposta sarà fornita al più presto.</p>
    <p>Il Titolare del trattamento dati<br />Moro Davide<br />(Firma apposta a mezzo stampa ai sensi dell’art. 3 del D. Lgs. n. 39/1993)</p>
    <h2>INFORMATIVA COOKIES</h2>
    <p>Questo sito utilizza Cookies. L’attività di navigazione nel sito costituisce accettazione e consenso al trattamento dei dati raccolti attraverso i cookies nei termini e con le modalità che seguono.</p>
    <p>Per cookies si intende un dato informativo trasmesso dai server della Società al computer dell'utente al fine di permetterne la rapida identificazione e la gestione delle operazioni necessarie a fornire i servizi richiesti o ad ottimizzare la navigazione.</p>
    <p>Per il funzionamento del sito vengono utilizzati dei cookies tecnici di sessione/navigazione, e cookies c. d. analitici.</p>
    <p>Cookies tecnici di navigazione: sono impiegati per ottimizzare la navigazione e l’adempimento delle procedure di accesso alle varie parti del sito. Vengono installati direttamente al momento dell’accesso da parte dell’utente. I cookies di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente.</p>
    <p>Cookies tecnici di analisi: sono impiegati in modo anonimo per analizzare la navigazione degli utenti, statistiche sulle visite, sui tempi di permanenza nel sito e sulle interazioni degli utenti con i contenuti del sito stesso. Le informazioni generate da questa tipologia di cookies sull'utilizzo del sito web potranno essere impiegate per il posizionamento di banner pubblicitari e suggerimenti commerciali durante la navigazione, senza riferimenti diretti al profilo del singolo utente. In particolare, i dati vengono elaborati attraverso l’uso di Google Analytics. Tale servizio utilizza delle procedure di analisi e sistemi di monitoraggio autonomi. Per maggiori informazioni sul funzionamento di Google Analytics, sui cookies utilizzati da Google Analytics e sulla policy privacy specifica del servizio si invita ad accedere al seguente link: <a href="http://www.google.it/intl/it/analytics/privacyoverview.html">http://www.google.it/intl/it/analytics/privacyoverview.html</a>.</p>
    <p>Tutti i dati sono raccolti dai cookies in modo anonimo, senza identificare i singoli visitatori. Tuttavia tali dati potrebbero, da soli o associati ad altri elementi, permettere di trarre informazioni personali o identificare i singoli utenti, ove rielaborati con specifiche procedure (ad esempio da parte delle Autorità in caso di commissione di reati o abusi informatici).</p>
    <p>L’utente può sempre disattivare i cookies modificando autonomamente le impostazioni del browser da lui utilizzato, ma tale disattivazione potrà rallentare o modificare l’accesso al sito stesso.</p>
    <p>Attraverso le impostazioni del proprio browser di navigazione, l’utente può decidere autonomamente di monitorare l’attività dei cookies (ad esempio verificando quelli attivi in un determinato momento o impostando un segnale di avviso al momento dell’attivazione), disattivarli o modificare le preferenze già impostate. Per la modifica delle impostazioni del browser è possibile consultare le pagine di assistenza messe a disposizione su internet dai vari produttori, di seguito i link relativi ai più utilizzati:</p>
    <ul>
        <li><a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
        <li><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a></li>
        <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">Safari</a></li>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=it">Google Chrome</a></li>
        <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a></li>
    </ul>
    <p>Se non viene utilizzato nessuno dei browser sopra elencati, è possibile cercare informazioni digitando il termine “Cookies” abbinato al nome del browser per verificare le procedure da seguire e l’ubicazione della cartella specifica.</p>
    <p>Il collegamento seguente consente di modificare impostazioni relative ai cookie Flash.</p>
            <p>disabilitazione dei Cookie flash</p>
            <p>L’utente deve essere consapevole che la modifica o la disattivazione dei cookies potrà rallentare od ostacolare l'accesso ad alcune parti del sito, rallentarne la navigazione, nonché impedire il completamento di alcune procedure di accesso. Ogni inadempimento o danno possibile derivante all’utente in merito, sarà da considerarsi a suo esclusivo carico.</p>
        </div>
        <FooterOne />
        </React.Fragment>
    )
}
export default InformativaPrivacy;