import {React,useRef} from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Label } from "reactstrap";
import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const CommentForm = ({ name,title, layout,parent_id }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const captchaRef = useRef(null)
  const onSubmit = async (data) => {
    if (!captchaRef.current.getValue()){
      alert("compilare il captcha")
      return 
    }
    let formData = new FormData()
    try {
        data[`parent_id`] =  parent_id
        formData.append("data",JSON.stringify(data))

        const url = `/api/${name}_comment/`
        
        fetch(url, {method:"POST",body:formData}).then(
            response => {
                if (response.ok){
                    data["tipo"] = "commento"
                    data["oggetto"] = name
                    formData = new FormData()
                    formData.append("data",JSON.stringify(data))
                    fetch('/api/send_email',{
                        method:"POST",body:formData
                    }).then(
                        response =>console.log(response.status)
                    )
                    alert("commento inserito correttamente")
                    window.location.reload(false);
                }else{
                    alert("errore inserimento commento")
                }
            }
        )

  } catch (error) {
    console.log(error);
      alert('Errore creazione commento');
  }
  };

  return (
      <section className="section" id={`${name}-form`}>
        <Container>
          { title !== "" ? (<Row className="mt-5 pt-2 justify-content-center">
          <Col lg={7}>
              <div className="text-center mb-4">
                <h3 className="text-uppercase">{title}</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
            </Row>) : ""}
          <Row className="mt-5 pt-2 justify-content-center">
            <Col lg={9}>
              <div className="custom-form">
                <form onSubmit={handleSubmit(onSubmit)} name={`${name}Form`}>
                  {layout.reduce((acc, field, index) => {
                    if (index === 0 || acc[acc.length - 1].totalWidth + field.fieldDimensions > 12) {
                      acc.push({ fields: [field], totalWidth: field.fieldDimensions });
                    } else {
                      acc[acc.length - 1].fields.push(field);
                      acc[acc.length - 1].totalWidth += field.fieldDimensions;
                    }
                    return acc;
                  }, []).map((row, rowIndex) => (
                    <Row key={rowIndex} className="align-items-center">
                      {row.fields.map((field, fieldIndex) => (
                        <Col key={fieldIndex} sm={field.fieldDimensions}>
                          <div >
                            {field.fieldType === "checkbox" ? (
                              <div className="d-flex align-items-center mt-4">
                                <Label for={field.fieldName} className="form-check-label m-2">
                                  {field.fieldLabel}{field.fieldValidation.required && "*"} :
                                </Label>
                                <input
                                  type="checkbox"
                                  className="form-check-input m-2"
                                  {...register(field.fieldName, field.fieldValidation)}
                                />
                              </div>
                            ) : (
                                <>
                                <Label for={field.fieldName} className="form-label mt-3">
                                {field.fieldLabel}{field.fieldValidation.required && "*"}:</Label>
                              <input
                                type={field.fieldType}
                                className="form-control"
                                {...register(field.fieldName, field.fieldValidation)}
                              /></>
                            )}
                            {errors[field.fieldName] && (
                              <p className="red-text">Questo campo è obbligatorio</p>
                            )}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ))}
                  <Row>
                    <Col className="m-3">
                    <ReCAPTCHA
                    sitekey="6Ld0K5MqAAAAAIUZyqa44NUYxWslSdlw4tlJdMeX"
                    ref={captchaRef} />
                   </Col>
                   </Row>
                  <Row>
                    <Col lg={12} >
                      <div className="mt-3 text-end">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  );
};

export default CommentForm;
