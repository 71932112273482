import React, { useEffect, useState } from 'react';
import { Label,Row,Col } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import { useNavigate } from 'react-router-dom';


const SubCategoryOverview = ({ category }) => {
  const navigate = useNavigate();
  const setSelectedCategory = () => {
    // onSelect(category);
    navigate(`/admin/categoria/${category.macro_categoria.id}/${category.id}`);
  };

  return (
    <>
    <Row className="m-2 justify-content-space-between product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedCategory}>Dettagli</button>
      </Col>
      <Col xs="8">
        {category.nome}
      </Col>
    </Row></>
  );
};

const SubCategoryForm = ({ idCategory,subCategory }) => {
  const auth = useAuth();
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: subCategory });
  const navigate = useNavigate();
  const textFields = [
    { value: subCategory ? subCategory.nome : "", label: 'nome',validation:{required:true} },
    { value: subCategory ? subCategory.descrizione : "", label: 'descrizione',validation:{} },
    { value: subCategory ? subCategory.descrizione_eng : "", label: 'descrizione_eng',validation:{} },
    // { value: category ? category.descrizione_completa : "", label: 'descrizione_completa' },
    // { value: category ? category.nome : "", label: 'nome' }
  ];

  // const imageFields = [category ? category.foto1 : ""];
  const handleDelete = async () =>{
    if(subCategory?.prodotti?.length > 0){
      alert("impossibile eliminare categoria con prodotti associati, cambiare categoria ai prodotti oppure eliminare")
    }else{
    // delete request
    const response = await fetch(`/api/admin/categories/${subCategory.id}`,{
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: new FormData()
    })

    if (response.ok){
      alert("oggetto eliminato correttamente")
    }else{
      alert("errore eliminazione oggetto")
    }
  }
  }
  const onSubmit = (data) => {
    if (JSON.stringify(data) === JSON.stringify(subCategory)) {
      alert('Form data is equal to default values. Submission prevented.');
      return;
    }
    
    const formData = new FormData();
    
    data = {
      ...data,
      macrocategoria_id : parseInt(idCategory,10)
    };
    
    delete data['macrocategoria']
    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/categories";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/categories/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
        if (response.ok){
        let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
        alert(str_resp)
        navigate(`/admin/categoria/${idCategory}`)
    }else if(response.status === 401){
      alert("sessione interrotta, rieffettuare il login")
      navigate("/login")
    } 
    else{
      alert("errore : " + response.json())
    }
  })
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      <button type="submit" className="btn btn-primary m-3">Save</button>
    </form>
    <div>
      {subCategory && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
    </div></>
  );
};

const SubCategoryManager = ({ idSubCategory,idCategory }) => {
  const [loading, setLoading] = useState(true);
  const [subCategory, setSubCategory] = useState(null);
  useEffect(() => {
    async function fetchData() {
      let subCategoryResponse = null;
      let subCategoryData = null;
      if (idSubCategory == 0){
        return []
      }
      if (idSubCategory !== null){
        subCategoryResponse = await fetch(`/api/categories/${idSubCategory}`);
      }
      if ((subCategoryResponse!== null && !subCategoryResponse.ok)) {
        console.log('error');
        return;
      }
      if (subCategoryResponse !== null){
        subCategoryData = await subCategoryResponse.json();
      }
    return [subCategoryData];
    }

    fetchData().then((data) =>{
        setSubCategory(data[0])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idSubCategory]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50 row" >
        <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
        </div>
        <div className='col-10'>
          <h2>{subCategory !== null ? "modifica sotto categoria" : "inserisci nuova sotto categoria"}</h2>
          </div>
        </div>
      </div>
    <div className="row align-items-center justify-content-center m-3">    
      <div className="col-12 section-bg ">
        <SubCategoryForm subCategory={subCategory} idCategory={idCategory}/>
        </div>
        </div>
       </section> 
  );
};

export default SubCategoryManager;
