import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Preloader from "../helper/Preloader";
import HeaderOne from "./HeaderOne";
import FooterOne from "./FooterOne";
import Slider from "react-slick";
import Comment from './Comment';
import Form from './Form';
import CommentForm from "./CommentForm";

const ProductDetails = ({ productId }) => {
    const formLayout = {
        name:'product',title:"Commenta un prodotto e ti risponderemo via mail",layout:[
            {fieldName:'autore',fieldDimensions:12,fieldLabel:'Nome e cognome',fieldValidation:{required:true},fieldType:'text'},
            {fieldName:'email',fieldDimensions:6,fieldLabel:'Indirizzo email',fieldValidation:{required:true},fieldType:'email'},
            {fieldName:'contenuto',fieldDimensions:12,fieldLabel:'Commento',fieldValidation:{required:true},fieldType:'textarea'},
        ]
    }    
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [media, setMedia] = useState([]);
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti',{state:product.nome})
    }
    // Function to fetch product data from the API
    const fetchProduct = async () => {
        try {
            const response = await fetch(`/api/products/${productId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProduct(data);
            console.log(data);
            setMedia([{url:data.foto1,type:"img"},{ url:data.foto2,type:"img"}, { url:data.foto3,type:"img"},{ url:data.video,type:"video"}].filter(i => i.url !== '/assets/images/products/fnd.jpg' && i.url !== ""));
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-right m-10" />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button className={className} onClick={onClick}>
                <i className="las la-arrow-left m-10" />
            </button>
        );
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [],
    };

    useEffect(() => {
        if (productId) {
            fetchProduct();
        }
    }, [productId]);

    if (loading) {
        return <Preloader />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!product) {
        return <div>No product found</div>;
    }

    return (
        <div className="product-details mt-32 padding-b-120">
            <div className="container container-two">
                <div className="row gy-4 product-sidebar section-bg align-items-center">
                    <div className="col-1">
                        <Link to="/prodotti/">
                            <button type="button" className="btn btn-main w-100 pill">
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        </Link>
                    </div>
                    <div className="col-10 text-center">
                        <h4>Scheda prodotto</h4>
                    </div>
                    <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />

                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-product-details"
                            role="tabpanel"
                            aria-labelledby="pills-product-details-tab"
                            tabIndex={0}
                        >
                            {/* Product Details Content Start */}
                            <div className="product-details__thumb text-center fw-bold mb-40">
                                {product.nome}
                            </div>
                            <div className="product-details">
                            <div className="row align-items-center justify-content-center">
                            {media.length > 1 ? (
                                <div className="col-8 col-lg-6 text-center">
                                    <Slider {...settings}>
                                    {media.map((item, index) => (
                                        item.type === "img" ? (
                                        <img src={process.env.PUBLIC_URL + item.url} alt={`slide ${index + 1}`} className="img-fluid rounded" key={index} />
                                        ) : (
                                        <video src={process.env.PUBLIC_URL + item.url} alt={`video`}  controls="controls" autoPlay={true} key={index} />
                                        )
                                    ))}
                                    </Slider>
                                </div>
                                ) : (
                                <div className="col-10 col-lg-8 text-center">
                                    {media[0].type === "img" ? (
                                    <img src={process.env.PUBLIC_URL + media[0].url} alt="default" className="img-fluid rounded" />
                                    ) : (
                                    <video src={process.env.PUBLIC_URL + media[0].url} alt="default" className="img-fluid rounded" controls autoPlay />
                                    )}
                                </div>
                                )}

                                </div>
                                <div className="product-details__desc text-center">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-4">codice : {product.codice}</div>
                                        <div className="col-4">marca : {product.marca !== null ? product.marca.nome : "non specificata"}</div>
                                    </div>
                                    <div className="mt-40">{product.descrizione_completa}</div>
                                </div>
                                <div className="product-sidebar__top position-relative flx-between gap-1 col-12" />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {/* ======================= Product Sidebar Start ========================= */}
                        <div className="product-sidebar row justify-content-center">
                            {product.allegato !== "" ? (
                                <>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill "
                                            onClick={goToContactPage}
                                        >
                                            Contattaci
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill"
                                        >
                                            <a href={process.env.PUBLIC_URL + product.allegato} download={true}>
                                            Scarica la scheda tecnica
                                            </a>
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill px-sm-5"
                                        onClick={goToContactPage}
                                    >
                                        Contattaci
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="comment-form mt-64 mb-64" id="comment-box">
                  <CommentForm name={formLayout.name} title={formLayout.title} layout={formLayout.layout} parent_id={productId}/>
                </div>
                {/* Comment Start */}
                <div className="comment mt-64 mb-64">
                  <h5 className="mb-32">{product.commenti.length} Commenti</h5>
                  <ul className="comment-list">
                  {product.commenti.map((commento,index) =>{
                    return <Comment comment={commento}/>
                  })}  
                  </ul>
                </div>
                </div>
            </div>
        </div>
    );
};

const ProductDetailsPage = () =>{
    const {id} = useParams();
    return (
            <>
              {/* Preloader */}
              <Preloader />
        
              {/* HeaderOne */}
              <HeaderOne />
        
              {/* AllProduct */}
              <ProductDetails productId={id}></ProductDetails>
                
              {/* FooterOne */}
              <FooterOne />
            </>
          );
}
export default ProductDetailsPage;