import React, { useEffect,useState } from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import News from "../components/News";
const NewsPage = () => {

  const [data,setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetch(`/api/archivio_news`)
      .then((response) =>{
        if (!response.ok){
          throw new Error('errore di rete');
        }
        return response.json()
      })
      .then((data) =>{
        setData(data);
        setLoading(false);
      })
      .catch((error) =>{
        setError(error);
        setLoading(false);
      })
  },[]);

  if (loading){
    return <Preloader></Preloader>
  }
  if (error){
    return <div>{error}</div>
  }
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />

      {/* Blog */}
      <News news={data}/>

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default NewsPage;
