import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";
import FooterOne from "../components/FooterOne";
import { useNavigate } from "react-router-dom";
const ConsulenzaEnergetica = () =>{
    const navigate = useNavigate();
    const goToContactPage = () =>{
        navigate('/contatti')
    }
    return (
        <section className=" position-relative z-index-1 overflow-hidden">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2>Consulenza Energetica</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-11 col-sm-10 text-center">
                <h4 className="fw-bold fs-3">Progettazione per illuminazione pubblica e privata: maggiore potenza e più economia energetica</h4>
                <p className="fs-5">Desiderate maggiore potenza e sicurezza? L’uso di sistemi di illuminazione realizzati con tecnologie di nuova generazione a economia energetica, consente la riduzione dei consumi nel rispetto di normative e leggi in vigore.</p>
                <p className="fs-5">Il Consulente Energetico progetta e gestisce i lavori di illuminazione pubblica e privata in grado di soddisfare tutte le prescrizioni di sicurezza, risparmio energetico, efficienza e durata nel tempo.</p>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <div className="col-11 col-sm-10 ">
                <h4 className="fw-bold fs-3 text-center">Il nostro intervento di Consulenza e Realizzazione di Progetti è richiesto in opere di:</h4>
                <ul className="list-circle fs-5"> 
                    <li>Aggiornamento impianti di illuminazione pubblica e illuminazione privata, impianti di illuminazione esterni e interni a garanzia di un miglioramento delle prestazioni. Incremento di risultati in termini di efficienza energetica in conformità con le disposizioni normative regionali e nazionali.</li>
                    <li>Illuminazione stradale Smart City realizzata con l’obiettivo di ottenere un sistema intelligente di gestione degli impianti con la corretta Potenza di Illuminazione e con l’Efficienza energetica.</li>
                    <li>Illuminazione esterna per piazze, parcheggi e viali.</li>
                    <li>Illuminazione esterna e interna di capannoni industriali, acciaierie, ATEX Zone 1 e 2.</li>
                    <li>Illuminazioni esterna di impianti sportivi, campi da calcetto a 5/7, calcio a 11, tennis e padel, campi di atletica e altri spazi di allenamento.
                    </li>
                </ul>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50">
          <div className="col-11 col-sm-10 text-center section-bg mt-5">
                <h3 className="fw-bold fs-3 mb-5 mt-5">Richiedi un preventivo gratuito e personalizzato</h3>
                <p className="fs-5" style={{marginBottom:50,}}>Se devi realizzare o adeguare il tuo impianto di Illuminazione pubblica o privata, chiedi un consiglio al nostro Consulente Energetico illustrandoci le tue esigenze e inviando i dati tecnici, disegni e documenti utili a sviluppare un preventivo: al più presto ti contatterà per un preventivo gratuito e personalizzato per le tue necessità!</p>
                <button
                        type="button"
                        className="btn btn-main w-100 justify-content-center align-items-center gap-2 pill px-sm-5 fs-3 mb-3"
                        onClick={goToContactPage}
                    >
                        Contattaci
                    </button>
            </div>
        </div>
        <div className="row align-items-center justify-content-center mb-50" style={{marginTop:50,}}>
          <div className="col-10 col-lg-8 text-center fs-3">
                <div className="fw-bold border rounded">Oggi la sensibilità delle persone e delle istituzioni riguardo alle tematiche di risparmio energetico e riduzione di inquinamento luminoso che l’illuminazione led può garantire, è in aumento. Così come continua ad aumentare la volontà di ridurre i consumi e di migliorare l’efficienza energetica e di illuminazione degli impianti pubblici e privati.</div>
            </div>
        </div>
        </section>
    )
};

const ConsulenzaEnergeticaPage = () =>{
    return (
        <section className="change-gradient">
        <Preloader />
        <HeaderOne />
        <ConsulenzaEnergetica />
        <FooterOne />
        </section>
    )
}
export default ConsulenzaEnergeticaPage;