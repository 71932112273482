import Slider from "react-slick";
import FooterOne from "../components/FooterOne";
import HeaderOne from "../components/HeaderOne";
import Preloader from "../helper/Preloader";
import ArchivioInstallazioni from "../components/ArchivioInstallazioni";
import { useEffect,useState } from "react";
const ArchivioInstallazioniPage = () =>{
  const [installazioni, setInstallazioni] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetch('/api/installazioni')
      .then((response) =>{
        if (!response.ok){
          throw new Error('errore di rete');
        }
        return response.json()
      })
      .then((data) =>{
        setInstallazioni(data);
        setLoading(false);
      })
      .catch((error) =>{
        setError(error);
        setLoading(false);
      })
  },[]);
    if (loading){
      return <Preloader></Preloader>
    }
    if (error){
      return <p>{error}</p>
    }
    return (
        <>
          {/* Preloader */}
          <Preloader />
    
          {/* HeaderOne */}
          <HeaderOne />
        
          {/* AllProduct */}
          <ArchivioInstallazioni installazioni={installazioni}/>   
    
          {/* FooterOne */}
          <FooterOne />
        </>
      );
}
export default ArchivioInstallazioniPage;
