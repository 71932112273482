import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import NewsDetails from "../components/NewsDetails";
const NewsDetailsPage = () => {

  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />

      {/* BlogDetails */}
      <NewsDetails />

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default NewsDetailsPage;
