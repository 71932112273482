import ProductManager from '../components/ProductManager';
import { useAuth } from '../provider/authProvider';
import Preloader from '../helper/Preloader';
import { Navigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
const ProductManagerPage = () =>{
    let {id} = useParams();
    if (id === undefined | id === null){
        id = null
    }
    const {token,loading} = useAuth()
    console.log(token)
    if (loading){
        return <Preloader />
    }
    if (!token){
        return <Navigate to='/login' replace />
    }
    return (<ProductManager idProduct={id}/>)
}

export default ProductManagerPage;