import { useState } from "react";
import { Controller } from "react-hook-form";
const FileInput = ({ fieldName, filePath, control }) => { 
    const [file, setFile] = useState(filePath ? { name: filePath, type: filePath.split(".")[1] } : { name: "", type: "" }); 
    
    const handleFileChange = (e, onChange) => { 
      if (e.target.files && e.target.files[0]) { 
        setFile(e.target.files[0]); 
        onChange(e.target.files[0]); 
      } else { 
        setFile({ name: "", type: "" }); 
        onChange(null); 
      } 
    }; 
    return (
    <div> 
      <label htmlFor={fieldName} className="form-label mt-3">{fieldName}</label> 
      <Controller control={control} name={fieldName} render={({ field: { value, onChange, ...field } }) => 
      (<input {...field} onChange={(e) => handleFileChange(e, onChange)} type="file" id={fieldName} />)} /> 
      <div>{file && `${file.name} - ${file.type}`}</div> 
      </div>); };

export default FileInput;