import FooterOne from "../components/FooterOne"
import HeaderOne from "../components/HeaderOne"
import Preloader from "../helper/Preloader"

const About = () =>{
    return (
        <div className="container">
        <div className="section-heading style-center">
          <div className="section-heading__title mb-50" >
            <h2>Chi siamo</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center text-sm-start">
            <div className="col-sm-10 col-12 text-center mb-5">
                <p className="fs-5">Lediamoluce è un’azienda in grado di offrire soluzioni personalizzate per le esigenze specifiche dei qualsiasi cliente. Grazie alla vasta gamma di prodotti presenti in catalogo, che includono illuminazione a LED per lo sport, l'industria, l’arredo urbano, l’architettura di interni ed esterni e molti altri settori, siamo in grado di soddisfare tutte le esigenze e garantire un notevole risparmio energetico.
I nostri prodotti sono realizzati con materiali di alta qualità e sono progettati per garantire prestazioni ottimali e lunga durata.</p>

            </div>
            <div className="col-sm-8 col-12 text-center">
                <img src={process.env.PUBLIC_URL + "/assets/images/home/2406237_Banner_NOLEGGIO_OPERATIVO.jpg"}></img>
            </div>
            <div className="col-sm-10  col-12 text-center text-sm-left mt-5">
            <p className="fs-5">Se sei alla ricerca di soluzioni di illuminazione a LED per il risparmio energetico, contattaci oggi stesso per una consulenza personalizzata e un preventivo gratuito. Siamo qui per aiutarti a trovare la soluzione migliore per le tue esigenze di illuminazione a LED.
            </p>
                <p className="fw-bold fs-3 mt-3">Lavoriamo su commissione, ci rivolgiamo solo a rivenditori e partite iva.
                </p>
            </div>  
        </div>
    </div>
    )
}

const AboutPage = ()=>{
    return (
        <section className="change-gradient">
            <Preloader /> 
            <HeaderOne />
            <About />
            <FooterOne />
        </section>
    )
}

export default AboutPage;