import React from "react";
import Progress from "../components/Progress";
import Login from "../components/Login";
import Preloader from "../helper/Preloader";
const LoginPage = () => {

  return (

    <>
      {/* Progress */}
      <Preloader />

      {/* Login */}
      <Login />
    </>
  );
};

export default LoginPage;
