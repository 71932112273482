import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import Form from "../components/Form";
const ContactPage = ({productName}) => {
  const formLayout = {
    name:'contattaci',title:"Compila il form e ti risponderemo via mail",layout:[
        {fieldName:'nome',fieldDimensions:12,fieldLabel:'Nome',fieldValidation:{required:true},fieldType:'text'},
        {fieldName:'cognome',fieldDimensions:12,fieldLabel:'Cognome',fieldValidation:{required:true},fieldType:'text'},
        {fieldName:'email',fieldDimensions:6,fieldLabel:'Indirizzo email',fieldValidation:{required:true},fieldType:'email'},
        {fieldName:'telefono',fieldDimensions:6,fieldLabel:'Numero di telefono',fieldValidation:{required:true},fieldType:'number'},
        {fieldName:'azienda',fieldDimensions:6,fieldLabel:'Azienda',fieldValidation:{},fieldType:'text'},
        {fieldName:'citta',fieldDimensions:6,fieldLabel:'Città',fieldValidation:{},fieldType:'text'},
        {fieldName:'messaggio',fieldDimensions:12,fieldLabel:'Messaggio',fieldValidation:{required:true},fieldType:'textarea'},
    ]
}    
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />


      {/* Contact */}
      <Form name={formLayout.name} title={formLayout.title} layout={formLayout.layout} productName={productName}/>

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default ContactPage;
