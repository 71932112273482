import React, { useEffect, useState } from 'react';
import { Label,Row,Col } from 'reactstrap';
import Preloader from '../helper/Preloader';
import { useForm,Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import { useNavigate } from 'react-router-dom';
import FileInput from './FileInput';
const SubCategoryOverview = ({ macro_category_id,category, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedCategory = () => {
    // onSelect(category);
    console.log(category)
    navigate(`/admin/categoria/${macro_category_id}/${category.id}`);
  };
  
  return (
    <>
    <Row className="m-2 justify-content-space-between product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedCategory}>Dettagli</button>
      </Col>
      <Col xs="8">
        {category.nome}
      </Col>
    </Row></>
  );
};

const CategoryForm = ({ category }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue,formState:{errors} } = useForm({ defaultValues: category });
  
  const textFields = [
    { value: category ? category.nome : "", label: 'nome' ,validation:{required:true}},
    { value: category ? category.descrizione : "", label: 'descrizione' ,validation:{}},
    { value: category ? category.descrizione_eng : "", label: 'descrizione_eng',validation:{} },
    // { value: category ? category.descrizione_completa : "", label: 'descrizione_completa' },
    // { value: category ? category.nome : "", label: 'nome' }
  ];

  const imageFields = [category ? category.foto1 : ""];
  const handleDelete = async () =>{
    if (category?.categorie?.length > 0){
      alert("impossibile eliminare macro categoria con categorie associate, eliminare prima le sottocategorie")
    }else{
      // delete request
      const response = await fetch(`/api/admin/macro_categories/${category.id}`,{
        method: "DELETE",
        headers: {
          "Authorization": "Bearer " + auth.token,
        },
        body: new FormData()
      })

      if (response.ok){
        alert("oggetto eliminato correttamente")
        navigate("/admin")
      }else{
        alert("errore eliminazione oggetto")
      }
    }
  }
  const onSubmit = (data) => {
    console.log(data);
    if (JSON.stringify(data) === JSON.stringify(category)) {
      alert('Dati non modificati, aggiornamento evitato');
      return;
    }
    
    const formData = new FormData();
    if (data.foto1 && data.foto1 instanceof File) {
      formData.append("foto1", data.foto1);
    }
    
    data = {
      ...data,
      foto1: data.foto1 && data.foto1 instanceof File ? `/assets/images/products/${data.foto1.name}` : category?.foto1 ? category.foto1 : "",
    };
    console.log(data)

    delete data['categorie']
    formData.append("data", JSON.stringify(data));
    
    let url = "/api/admin/macro_categories";
    let method = "POST";
    
    if (data && data.id) {
      url = `/api/admin/macro_categories/${data.id}`;
      method = "PATCH";
    }
    fetch(url, {
      method: method,
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
      body: formData
    })
      .then(response => {
        if (response.ok){
            let str_resp = method === "PATCH" ? "modifica avvenuta correttamente" : "creazione avvenuta correttamente"
            alert(str_resp)
            navigate("/admin")
        }else if(response.status === 401){
          alert("sessione interrotta, rieffettuare il login")
          navigate("/login")
        } 
        else{
          alert("errore : " + response.json())
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      {textFields.map(field => (
        <><label htmlFor={field.label} className="form-label mt-3">{field.label}{field.validation.required && "*"}:{" "}</label>
        <input type="text" className="form-control" {...register(field.label,field.validation)} />
        {errors[field.label] && (
          <p className="red-text">Questo campo è obbligatorio</p>
          )}</>
      ))}
      <div>
        {imageFields.map((img, index) => (
          <FileInput key={index} fieldName={`foto${index + 1}`} filePath={img ? img : ""} control={control} />
        ))}
      </div>
      <button type="submit" className="btn btn-primary m-3">Save</button>
    </form>
    <div>
      {category && <button  className="btn btn-primary m-3" onClick={handleDelete}>Elimina</button>}
    </div></>
  );
};

const CategoryManager = ({ idCategory }) => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [subCategories,setSubCategories] = useState(null);
  const [show,setShow] = useState(false);
  const navigate = useNavigate();
  
  const handleSubCategories = () =>{
    setShow(true);    
  }
  
  const handleNewSub = () =>{
    navigate(`/admin/categoria/${idCategory}/0`);
  }
  
  useEffect(
    ()=>{
      if (show){
      setSubCategories(category.categorie.map((cat) =>{
        return <SubCategoryOverview category={cat} macro_category_id={idCategory}/>   
      }))}
    },[show])

  useEffect(() => {
    async function fetchData() {
      let categoryResponse = null;
      let categoryData = null;
      if (idCategory !== null){
        categoryResponse = await fetch(`/api/macro_categories/${idCategory}`);
      }
      if ((categoryResponse!== null && !categoryResponse.ok)) {
        console.log('error');
        return;
      }
      if (categoryResponse !== null){
        categoryData = await categoryResponse.json();
      }
    return [categoryData];
    }

    fetchData().then((data) =>{
        setCategory(data[0])
        setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }, [,idCategory]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" position-relative z-index-1 overflow-hidden">
    <div className="section-heading style-center">
        <div className="section-heading__title mb-50 row" >
        <div className="col-1">
        <Link to="/admin">
            <button type="button" className="btn btn-main w-100 pill">
                <i className="fa fa-arrow-left"></i>
            </button>
        </Link>
        </div>
        <div className='col-10'>
          <h2>{category !== null ? "modifica categoria" : "inserisci nuova categoria"}</h2>
          </div>
        </div>
      </div>
    <div className="row align-items-center justify-content-center m-3">    
      <div className="col-12 section-bg ">
        <CategoryForm category={category}  />
        </div>
        </div>
    <div className="row align-items-center justify-content-center mb-50">    
        { category  && <div className="col-12 text-center">
        <button className="btn btn-primary m-3" onClick={handleNewSub}>Nuova sotto categoria</button>
        
        <button className="btn btn-primary m-3" onClick={handleSubCategories}>Sotto categorie</button>
        <div className='row align-items-center m-3 section-bg'>
          {subCategories && (<><Row className="m-2 font-weight-bold justify-content-space-between">
    <Col xs="2">Seleziona</Col>
    <Col xs="8">Nome</Col>
    </Row><Row>{subCategories}</Row></>)}
        </div>
      </div>}
      </div> 
       </section> 
  );
};

export default CategoryManager;
