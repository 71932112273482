const Comment = ({comment}) => {
    return (
        <li className="comment-list__item d-flex align-items-start gap-sm-4 gap-3">
            <div className="comment-list__thumb flex-shrink-0">
            <img
                src="assets/images/thumbs/comment1.png"
                className="cover-img"
                alt=""
            />
            </div>
            <div className="comment-list__content">
            <div className="flx-between gap-2 align-items-start">
                <div>
                <h6 className="comment-list__name font-18 mb-sm-2 mb-1">
                    {comment.autore}
                </h6>
                <span className="comment-list__date font-14">
                    {comment.data}
                </span>
                </div>
            </div>
            <p className="comment-list__desc mt-3">
                {comment.contenuto}
            </p>
            </div>
        </li>
                    
    )
}
export default Comment;