import React, { useEffect,useState } from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import Blog from "../components/Blog";
import FooterOne from "../components/FooterOne";
import axios from "axios";
const BlogPage = () => {

  const [data,setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  function fetchData() {
    axios.all([
      axios.get('/api/blogs'),
      axios.get('/api/blogtags')
    ])
    .then(axios.spread((blogs, blogtags) => {
      setData({
        blogs: blogs.data,
        blogtags: blogtags.data
      });
      setLoading(false); // Set loading to false once data is fetched
    }))
    .catch(error => {
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading to false even if there's an error
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (loading){
    return <Preloader></Preloader>
  }
  if (error){
    return <div>{error}</div>
  }
  return (
    <>
      {/* Preloader */}
      <Preloader />

      {/* HeaderOne */}
      <HeaderOne />

      {/* Blog */}
      <Blog blogs={data.blogs} blogtags={data.blogtags}/>

      {/* BrandSectionOne
      <BrandSectionOne /> */}


      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default BlogPage;
