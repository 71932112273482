import { Navigate } from "react-router-dom"
import Preloader from "../helper/Preloader"
import { useAuth } from "../provider/authProvider"
import Admin from "../components/Admin"
const AdminPage = () =>{
    const {token,loading} = useAuth()
    console.log(token)
    if (loading){
        return <Preloader />
    }
    if (!token){
        return <Navigate to='/login' replace />
    }
    return (<Admin />)
}
export default AdminPage;