import {React, useEffect, useState} from "react";
import { set, useForm } from "react-hook-form";
import { Container, Row, Col, Label } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Preloader from "../helper/Preloader";
import { useAuth } from "../provider/authProvider";
import Pagination from "../components/Pagination";
import ThemeToggle from "./ThemeToggle";
const ProductOverview = ({ product, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedProduct = () => {
    onSelect(product);
    navigate(`/admin/prodotto/${product.id}`);
  };

  return (
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedProduct}>Dettagli</button>
      </Col>
      <Col xs="2">
        {product.codice}
      </Col>
      <Col xs="4">
        {product.nome}
      </Col>
      <Col xs="4">
        <img src={process.env.PUBLIC_URL + product.foto1} className="img_overview" alt="product preview" />
      </Col>
    </Row>
  );
};

const CategoryOverview = ({ category, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedCategory = () => {
    onSelect(category);
    navigate(`/admin/categoria/${category.id}`);
  };

  return (
    category.categorie &&
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedCategory}>Dettagli</button>
      </Col>
      <Col xs="2">
        {category.nome}
      </Col>
      <Col xs="4">
        {category.categorie.length}
      </Col>
      <Col xs="4">
        {category.categorie.map((categoria) => categoria.prodotti.length).reduce((acc, prod_per_cat) => acc + prod_per_cat,0)}
      </Col>
    </Row>
  );
};

const BlogOverview = ({ blog, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedBlog = () => {
    onSelect(blog);
    navigate(`/admin/blog/${blog.id}`);
  };

  return (
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedBlog}>Dettagli</button>
      </Col>
      <Col xs="2">
        {blog.titolo}
      </Col>
      <Col xs="4">
        {blog.data}
      </Col>
      <Col xs="4">
        <img src={process.env.PUBLIC_URL + blog.foto1} className="img_overview" alt="blog preview" />
      </Col>
    </Row>
  );
};

const NewsOverview = ({ news, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedNews = () => {
    onSelect(news);
    navigate(`/admin/news/${news.id}`);
  };

  return (
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedNews}>Dettagli</button>
      </Col>
      <Col xs="2">
        {news.titolo}
      </Col>
      <Col xs="4">
        {news.data}
      </Col>
      <Col xs="4">
        <img src={process.env.PUBLIC_URL + news.foto1} className="img_overview" alt="news preview" />
      </Col>
    </Row>
  );
};

const BrandOverview = ({ brand, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedBrand = () => {
    onSelect(brand);
    navigate(`/admin/brand/${brand.id}`);
  };

  return (
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedBrand}>Dettagli</button>
      </Col>
      <Col xs="3">
        {brand.nome}
      </Col>
    </Row>
  );
};
const BlogTagOverview= ({ blogtag, onSelect }) => {
  const navigate = useNavigate();
  const setSelectedBlogTag = () => {
    onSelect(blogtag);
    navigate(`/admin/blogtag/${blogtag.id}`);
  };

  return (
    <Row className="m-2 justify-content-center product-overview">
      <Col xs="2">
        <button className="btn btn-primary" onClick={setSelectedBlogTag}>Dettagli</button>
      </Col>
      <Col xs="3">
        {blogtag.nome}
      </Col>
    </Row>
  );
};


const ObjectOverview = ({ object, name, onSelect }) => {
  if (name === 'prodotto') {
    return <ProductOverview product={object} onSelect={onSelect} />;
  } else if (name === 'categoria') {
    return <CategoryOverview category={object} onSelect={onSelect} />;
  } else if (name === "blog") {
    return <BlogOverview blog={object} onSelect={onSelect} />;
  } else if (name === "brand") {
    return <BrandOverview brand={object} onSelect={onSelect} />;
  } else if (name === "news") {
    return <NewsOverview news={object} onSelect={onSelect} />;
  }else if(name == "blogtag"){
    return <BlogTagOverview blogtag={object} onSelect={onSelect} />
  }
};

const ListElements = ({ objects, name, onSelect, header, dep }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 8;
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredObjects, setFilteredObjects] = useState(objects);
  const [tag, setTag] = useState('');

  const location = useLocation();
  const { searchQuery } = location.state || '';

  const handlePageChange = (pageNumber) => {
    if (pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  useEffect(() => {
    let filtered = objects;
    if (selectedBrand) {
      filtered = filtered.filter(product => product.marca && product.marca.id === selectedBrand);
    }
    if (tag && tag.length > 2) {
      filtered = filtered.filter(product => product.nome.toLowerCase().includes(tag.toLowerCase()));
    }
    setFilteredObjects(filtered);
  }, [objects, selectedBrand, tag]);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  const currentObjects = filteredObjects.slice(indexOfFirstElement, indexOfLastElement);
  const totalPages = Math.ceil(objects.length / elementsPerPage);

  return (
    <>
      {name === 'prodotto' && (
        <Row className="mb-4">
          <form action="" className="filter-form pb-4 d-block">
            <Row className="gy-3 flx-between align-items-center">
              <Col xs="5" lg="6">
                <div className="flx-between">
                  <Label htmlFor="tag" className="form-label font-16">
                    Cerca prodotti
                  </Label>
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control border-gray-five common-input--withLeftIcon"
                    id="tag"
                    placeholder="Parola chiave..."
                    value={tag}
                    onChange={handleTagChange}
                  />
                  <span className="input-icon input-icon--left">
                    <img src="assets/images/icons/search-two.svg" alt="" />
                  </span>
                </div>
              </Col>
              <Col xs="5" lg="6">
                <div>
                  <Label className="form-label font-16" htmlFor="brand-select">Seleziona brand</Label>
                </div>
                <select
                  id="brand-select"
                  className="form-control border-gray-five common-input--withLeftIcon position-relative"
                  value={selectedBrand}
                  onChange={handleSelectChange}
                >
                  <option value="">Tutti</option>
                  {dep && dep.map((brand, index) => (
                    <option key={index} value={brand.id}>
                      {brand.nome}
                    </option>
                  ))}
                </select>
              </Col>
              <div className="line-separator"></div>
            </Row>
          </form>
        </Row>
      )}
      {header}
      {currentObjects.map((obj) => (
        <ObjectOverview key={obj.id} object={obj} name={name} onSelect={onSelect} />
      ))}
      <Col xs="12">
        <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </Col>
    </>)
}
const ElementView = ({ object, onSelect }) => {
  const [showTags,setShowTags] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(`/admin/${object.name}`);
  };
  const handleBlogTagClick = () => {
    navigate(`/admin/blogtag/`);
  };
  const handleFilterTag = () =>{
    setShowTags(!showTags)
  }
  if (object !== null) {
    return (
      <Container className="section-bg">
        <Row className="mb-4 align-items-center m-3 justify-content-center">
          <Col xs="4">
            <button className="btn btn-primary" onClick={handleButtonClick}>Nuovo {object.name}</button>
          </Col>
          {object.name === 'blog' && <><Col xs="4">
            <button className="btn btn-primary" onClick={handleBlogTagClick}>Nuovo tag</button>
          </Col><Col xs="4">
            <button className="btn btn-primary" onClick={handleFilterTag}>Lista tag</button>
          </Col></>}
        </Row>
        <Row>
          <ListElements objects={object.objects} name={object.name} onSelect={onSelect} header={object.header} brands={object.dep} />
        </Row>
        {object.name == "blog" && showTags && <Row>
          <ListElements objects={object.dep} name="blogtag" onSelect={onSelect} header={headers("blogtag")} dep={null}/>
        </Row>
        }
      </Container>
    );
  }
  return null;
};

const headers = (name) => {
  if (name === "prodotto") {
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="2">Codice</Col>
        <Col xs="4">Nome</Col>
        <Col xs="4">Anteprima</Col>
      </Row>
    );
  } else if (name === "categoria") {
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="2">Nome</Col>
        <Col xs="4">Categorie associate</Col>
        <Col xs="4">Numero prodotti associati</Col>
      </Row>
    );
  } else if (name === "blog") {
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="2">Titolo</Col>
        <Col xs="4">Data</Col>
        <Col xs="4">Anteprima</Col>
      </Row>
    );
  } else if (name === "brand") {
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="3">Nome</Col>
      </Row>
    );
  } else if (name === "news") {
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="2">Titolo</Col>
        <Col xs="4">Data</Col>
        <Col xs="4">Anteprima</Col>
      </Row>
    );
  }else if (name == "blogtag"){
    return (
      <Row className="m-2 font-weight-bold justify-content-center">
        <Col xs="2">Seleziona</Col>
        <Col xs="4">Nome</Col>
      </Row>
    );
  }
};

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [elementView, setElementView] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const handleLogout = () =>{
    fetch("/api/auth/logout/",{
      method: "GET",
      headers: {
        "Authorization": "Bearer " + auth.token,
      },
    }).then(response =>{
      if (response.ok){
        alert("disconnessione effettuata correttamente")
        auth.setToken(null)
        navigate("/login");
      }else if(response.status === 401){
        auth.setToken(null)
        navigate("/login");
      }
    })
  }
  useEffect(() => {
    async function fetchData() {
      const [prodotti, macro_categories, blog, brands, news,blogtags] = await Promise.all([
        fetch('/api/products'),
        fetch('/api/macro_categories'),
        fetch('/api/blogs'),
        fetch('/api/brands'),
        fetch('/api/archivio_news'),
        fetch('/api/blogtags')
      ]);
      const macroCategoriesData = await macro_categories.json();
      const blogData = await blog.json();
      const blogTagData = await blogtags.json();
      
      const prodottiData = await prodotti.json();
      const brandsData = await brands.json();
      const newsData = await news.json();
      return [prodottiData, macroCategoriesData, blogData, brandsData, newsData,blogTagData];
    }
    fetchData()
      .then(([prodotti, categories, blogs, brands, news,blogtags]) => {
        setElements([
          { name: 'prodotto', objects: prodotti, header: headers('prodotto'), dep:brands },
          { name: 'categoria', objects: categories, header: headers('categoria'),dep:null },
          { name: 'brand', objects: brands, header: headers("brand") ,dep:null},
          { name: 'blog', objects: blogs, header: headers("blog") ,dep:blogtags},
          { name: 'news', objects: news, header: headers("news") ,dep:null},
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleObjectClick = (name) => {
    const object = elements.find((e) => e.name === name);
    setSelectedObject(object);
  };

  const handleModifyUser = () =>{
    navigate("/admin/utente/")
  }
  useEffect(() => {
    if (selectedObject) {
      setElementView(
        <ElementView object={selectedObject} onSelect={handleItemSelect} />
      );
    }
  }, [selectedObject]);

  const handleItemSelect = (object) => {
    setSelectedItem(object);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <section className=" z-index-1 overflow-hidden">
      <div className="">
        <Row className="align-items-center justify-content-center">
        <Col xs="2" >
            <button className="btn btn-outline-light btn-sm pill" onClick={handleLogout}>Logout</button>
          </Col>
          <Col xs="2" >
            <button className="btn btn-outline-light btn-sm pill" onClick={handleModifyUser}>Gestisci utenza</button>
          </Col>
          
          <Col xs="6" className="text-center">
            <h2>Pannello di controllo</h2>
          </Col>
          
          <Col xs="2">
            <ThemeToggle />            
          </Col>
        </Row>
      </div>
      <Row className="align-items-center justify-content-center mb-50">
        <Col xs="8" lg="6" className="section-bg"></Col>
        <div className="navbar row justify-content-center">
          {elements.map((e) => (
            <Col xs="2" key={e.name}>
              <button className="btn btn-outline-light btn-lg pill" onClick={() => handleObjectClick(e.name)}>{e.name}</button>
            </Col>
          ))}
        </div>
      </Row>
      <Row className="align-items-center justify-content-center m-3">
        <Col xs="12" lg="10"></Col>
        {selectedObject && elementView}
      </Row>
    </section>
  );
};

export default Admin;

