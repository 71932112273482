import React from "react";
import { Link } from "react-router-dom";
const ProductCard = ({product}) =>{
  console.log(product.id)
    return (
      <div className="col-lg-4 col-sm-6 col-xl-3 col-6">
      <div className="product-item section-bg">
      <div className="product-item__thumb d-flex">
        <Link to={`/dettagli_prodotto/${product.id}`} className="link w-100">
          <img
            src={process.env.PUBLIC_URL + product.foto1}
            alt=""
            className="cover-img"
          />
        </Link>
      </div>
      <div className="product-item__content">
        <h6 className="product-item__title">
          <Link to={`/dettagli_prodotto/${product.id}`} className="link">
            {product.nome}
          </Link>
        </h6>
        <div className="product-item__info flx-between gap-2">
          { product.marca && <span className="product-item__author">
            {product.marca.nome}
          </span>}
        </div>
        <div className="product-item__bottom flx-between gap-2">
          <Link
            to={`/dettagli_prodotto/${product.id}`}
            className="btn btn-outline-light btn-sm pill"
          >
            Dettagli
          </Link>
        </div>
      </div>
    </div></div>);
  }

export default ProductCard;