import React from 'react';

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Number of pages to show at the beginning and end
    const adjacentPages = 2; // Number of adjacent pages to show

    if (totalPages <= maxPagesToShow * 2) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li className={currentPage === i ? "page-item__active" : "page-item"} key={i}>
            <div onClick={() => handlePageChange(i)}>{i}</div>
          </li>
        );
      }
    } else {
      for (let i = 1; i <= maxPagesToShow; i++) {
        pageNumbers.push(
          <li className={currentPage === i ? "page-item__active" : "page-item"} key={i}>
            <div onClick={() => handlePageChange(i)}>{i}</div>
          </li>
        );
      }

      if (currentPage > maxPagesToShow + adjacentPages) {
        pageNumbers.push(
          <li className="page-item" key="ellipsis1">
            <div>...</div>
          </li>
        );
      }

      for (let i = Math.max(currentPage - adjacentPages, maxPagesToShow + 1); i <= Math.min(currentPage + adjacentPages, totalPages - maxPagesToShow); i++) {
        pageNumbers.push(
          <li className={currentPage === i ? "page-item__active" : "page-item"} key={i}>
            <div onClick={() => handlePageChange(i)}>{i}</div>
          </li>
        );
      }

      if (currentPage < totalPages - maxPagesToShow - adjacentPages) {
        pageNumbers.push(
          <li className="page-item" key="ellipsis2">
            <div>...</div>
          </li>
        );
      }

      for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li className={currentPage === i ? "page-item__active" : "page-item"} key={i}>
            <div onClick={() => handlePageChange(i)}>{i}</div>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination common-pagination">
        {renderPageNumbers()}
        {totalPages > 1 && (
          <li className="page-item">
            <div
              className="flx-align gap-2 flex-nowrap"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
              <span className="icon line-height-1 font-20">
                <i className="las la-arrow-right" />
              </span>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
